<div class="container-fluid">

    <nav class="navbar navbar-expand-lg navbar-light bg-white ">
      <div class="container-fluid ">
        <button class="navbar-toggler ml-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse col-lg-7" id="navbarSupportedContent">
            
          <ul class="navbar-nav ml-auto mb-2 mb-lg-0">
              <li class="nav-item col-3">
                  <a class="nav-link active" aria-current="page" >الرئيسيه</a>
                </li>
                <li class="nav-item col-3">
                    <a class="nav-link" (click)= "scrollTo(target)" >من نحن</a>
                </li>
                <li class="nav-item col-3">
                    <a class="nav-link" (click)= "scrollTo(target2)" >تاريخنا</a>
                </li>
                <li class="nav-item col-3">
                    <a class="nav-link" (click)= "scrollTo(target3)">تواصل معنا</a>
                </li>
             
                <li class="nav-item col-3">
                    <a class="nav-link" [routerLink]="['']" >الانجليزيه</a>
                </li>
                
            </ul>
    
        </div>
        <div class="col-auto">
            <li class='list-inline-item nav-item phone  '>
              <a class='nav-link' href='tel:+201014422400' style="text-decoration: none; font: normal normal bold 19px/24px Abhaya Libre; color: black;">
              
                <span>01014422400</span>
                 <i class="fas fa-phone ml-1"></i>
              </a>
            </li> 

        </div>
      </div>
    </nav>
    
      <div class="row">
        <div class="col-lg-4 col-md-8 text-center">
          <img src="../../assets/Mixers -Final Version/Logo/Group 4.png" width="165px" alt="">
          <div class="left-nav" >
  
              <h3>أفضل التشطيبات الفاخرة لك
            </h3>
              <p class="mt-4">نحن ملتزمون ببناء منازل الأحلام لعملائنا الفريدين من خلال خبرتنا الواسعة ومصداقيتنا في صناعة التصميم الداخلي.
                </p>
              <button class="btn mt-5 pl-4 pr-4" (click)= "scrollTo(target2)"> ابدأ</button>
          </div>
        </div>
        <div class="col-lg-6 col-md-7" style="margin-top: 110px;">
          <div id="carousel-fade" class="carousel carousel-fade" data-ride="carousel" data-interval="2000">
        
        
            <div class="carousel-inner embed-responsive embed-responsive-16by9" role="listbox">
              <div class="carousel-item embed-responsive-item active">
                <img src="../../assets/Mixers -Final Version/Images/1.jpeg" class="img-responsive center-block" alt="First slide image" class="img-fluid">
                <div class="carousel-caption">
      
                </div>
              </div>
        
              <div class="carousel-item embed-responsive-item">
                <img src="../../assets/Mixers -Final Version/Images/2.jpeg" class="img-responsive center-block" alt="Second slide image" style="height: inherit;" width="100%">
                <div class="carousel-caption">
               
                </div>
              </div>
        
              <div class="carousel-item embed-responsive-item">
                <img src="../../assets/Mixers -Final Version/Images/3.jpeg" class="img-responsive center-block" alt="Third slide image" style="height: inherit;" width="100%">
                <div class="carousel-caption">
            
                </div>
              </div>
            </div>
  
            <div class="row">
  
              <a class="carousel-control-prev col-1" href="#carousel-fade" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next col-1" href="#carousel-fade" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
              
            </div>
          </div>
      </div>
      <div class="row mt-5">
        <div class="col-lg-6 text-end">
          <H2> اشتري .. وفر .. استرخى        </H2>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-lg-1 icon-class">
          <i class="fas fa-award"></i>
  
          
        </div>
        <div class="col-lg-3 text-class">
          <h5>اعلى جودة
        </h5>
          <p>
            يستخدم جميع أثاثنا أفضل المواد والخيارات لعملائنا.

          </p>
        </div>
        <div class="col-lg-1 icon-class">
          <i class="fas fa-truck"></i>
        </div>
        <div class="col-lg-3 text-class">
          <h5>الشحن مجانا
        </h5>
          <p>شحن مجاني في كل مرة تشتري أثاث منا دون استثناء.

        </p>
        </div>
        <div class="col-lg-1 icon-class">
          <i class="far fa-check-circle"></i>
        </div>
        <div class="col-lg-3 text-class">
          <h5>
            ضمان
          </h5>
          <p>
            كلما اشتريت منتجاتنا من الأثاث ، ستحصل على ضمان بدون استثناء.
        </p>
        </div>
      </div>
  
      <div class="row mt-5">
        <div class="col-lg-5 position-relative ">
          <div class="drawing">
          </div>
            <img src="../../assets/Mixers -Final Version/Images/WhatsApp Image 2020-12-20 at 11.31.30 AM.jpeg" class="img-fluid" alt="">
        </div>
        <div class="col-lg-6 " #target>
          <h4>نحن نقدم لك أفضل تجربة
        </h4>
          <p class="exp">

            Mixiers هي شركة تصميم داخلي تعمل على تضخيم الحياة الراقية لعملائها من خلال تقديم مزيج من مساحات المعيشة المتناغمة مع الحفاظ على التوازن النهائي بين الجمال والتفاصيل والفن.          </p>
        </div>
      </div>
  
      <div class="container">
  
        <div class="row mt-5">
          <div class="col-lg-4 numbers text-center">
            <h3 class="number-section">250+</h3>
            <p>نمط الأثاث <br> الإضاءة ، وأكثر من ذلك
            </p>
            <hr>
            <h3 class="number-section">500+</h3>
            <p>
                عملاء تتراوح بين الخاص <br> لمنازل اكبر
            </p>
            <hr>
            <h3 class="number-section">20+</h3>
            <p>عدد سنوات الخبرة في الداخل
                <br> صناعة التصميم
            </p>
          </div>
          <div class="col-lg-7 col-auto">
            <img src="../../assets/Mixers -Final Version/Images/2.jpeg" class="img-fluid" alt="">
          </div>
          <!-- <div class="col-lg-3 col-4">
            <img class="small-img" src="../../assets/Mixers -Final Version/Images/2.jpeg"  width="100%" alt="">
          </div> -->
        </div>
      </div>
  
  
      <div class="row mt-5 ml-3 position-relative" #target2>
        <div class="col-12">
  
          <h4>مشاريعنا المميزة
        </h4>
        </div>
        <div class="col-12">
          <owl-carousel-o class="first-owl" [options]="customOptions2">
            <ng-template carouselSlide >
              <img src="../../assets//Mixers -Final Version/Images/WhatsApp Image 2020-12-20 at 11.31.26 AM.jpeg" style="height: 371px;" alt="" class="img-fluid" >
            </ng-template>  
            <ng-template carouselSlide>
              <img src="../../assets/Mixers -Final Version/Images/WhatsApp Image 2020-12-20 at 11.31.27 AM.jpeg" alt="" style="height: 371px;" class="img-fluid">
            </ng-template>  
            <ng-template carouselSlide>
              <img src="../../assets/Mixers -Final Version/Images/WhatsApp Image 2020-12-20 at 11.31.28 AM.jpeg" alt="" style="height: 371px;" class="img-fluid">
            </ng-template>  
            <ng-template carouselSlide>
              <img src="../../assets/Mixers -Final Version/Images/WhatsApp Image 2020-12-20 at 11.31.29 AM.jpeg" alt="" style="height: 371px;" class="img-fluid">
            </ng-template>  
            <ng-template carouselSlide>
              <img src="../../assets/Mixers -Final Version/Images/WhatsApp Image 2020-12-20 at 11.31.30 AM.jpeg" alt="" style="height: 371px;" class="img-fluid">
            </ng-template>  
            <ng-template carouselSlide>
              <img src="../../assets/Mixers -Final Version/Images/WhatsApp Image 2020-12-20 at 11.31.31 AM.jpeg" alt="" style="height: 371px;" class="img-fluid">
            </ng-template>  
          </owl-carousel-o>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-12">
          <h4 >ماذا يقول عملاؤنا </h4>
        </div>
        <div class="col-12 mt-5 hand-slider">
  
          <owl-carousel-o [options]="customOptions" class="second-carousel">
            <ng-template carouselSlide class="p-5">
               <!-- <h3>The service Provider is very good and friendly</h3>  -->
              <b>قبل أن أسمع عن Mixiers ، عانيت من حل سريع. هذا عندما جربت Mixiers ، تلقيت ردودًا فورية على جميع استفساراتي وطلباتي. Mixiers هي شركة مذهلة وجديرة بالثقة. أوصي به بشدة!
              </b>
            <h5 class="pt-4">استاذ اسلام فاروق</h5>
              <!-- <p>Product Manger</p>  -->
            </ng-template>  
            <ng-template carouselSlide class="p-5">
             <b>إذا شعرت بخيبة أمل من شركات التصميم الداخلي الأخرى ، فلا يمكنني أن أوصي بـ Mixiers بما فيه الكفاية. شركة محترفة تلبي توقعاتك.
            </b>
             <h5 class="pt-5">استاذ مصطفى حامد</h5>
  
           </ng-template>  
           <ng-template carouselSlide>
           <b>كنت أرغب في إنهاء منزلي بسرعة لكنني أعلم أنه لن يكون من السهل العثور على شركة تصميم داخلي تلبي جميع احتياجاتي. حتى تلقيت توصية من صديق للاتصال بـ Mixiers. قام الفريق بتثبيته بالمواد والسرعة وكل شيء. مجرد خدمة رائعة!
          </b>
           <h5 class="pt-4">استاذ باسم حسين</h5>
  
         </ng-template>  
         <ng-template carouselSlide>
          <b>كانت لدي تجربة رائعة في تصميم منزلي باستخدام Mixiers. لقد حرصوا على فهم أسلوبي ورؤيتي. لقد وضعوا التصميم المثالي بالنسبة لي ، مما يضمن جودة عالية
          </b>
          <h5 class="pt-5">استاذ محمد مؤنس</h5>
  
        </ng-template>  
        <ng-template carouselSlide>
          <b>لم أكن أعرف أنا وزوجي بالضبط ما نريده. لقد احتجنا بالتأكيد إلى المساعدة في تصميم منزلنا الجديد وهذا حدث للتو عندما وجدنا Mixiers. لقد قدموا لنا أفكار التصميم المثالية في حدود ميزانيتنا. سأوصي بهم بشدة!
          </b>
          <h5 class="pt-4">استاذه هناء الحاتو</h5>
  
        </ng-template> 
        <ng-template carouselSlide>
          <b>إذا كنت تبحث عن تشطيب منزلي مثالي ، فإنني أوصيك بخلاطات. إنهم محترفون وسريعون ويقدمون أفضل جودة.
          </b>
          <h5 class="pt-5">  استاذ يوسف رافت</h5>
  
        </ng-template> 
          </owl-carousel-o>
        </div>
      </div>
  
      <div class="row ">
        <h1 class="m-auto col-lg-5">
تواصل معنا        </h1>
      
      </div>
      <!-- <div class="row mt-3">
        <p class="m-auto col-5 text-center" style="color: #808285;">
ادخل بياناتك و استفسارك و سوف نتواصل معك        </p>
      </div> -->
  
      <section #contact class='contact-us' #target3>
        <div class='container'>
            <div class='row'>
              <div class='col-lg-5 col-md-6 col-sm-10 mx-auto'>
                <div class='form-con'>
                  <p>لنتحدث! نود أن نسمع منك ، راسلنا إذا كان لديك أي استفسار أو ترغب في معرفة المزيد عن مشاريعنا.</p>
                  <form [formGroup]='updateForm' (ngSubmit)='onSubmit()'>
                    <input type='text' title='name' placeholder='الاسم' formControlName='name'/>
                    <app-field-error [displayError]="isFieldValid('name')" errorMsg='من فضلك أدخل إسمك'></app-field-error>
                    <input type='text' title='phone number' placeholder='رقم المحمول' formControlName='phone'/>
                    <app-field-error [displayError]="isFieldValid('phone')" errorMsg='يرجى إدخال رقم هاتف صالح'></app-field-error>
                    <input type='email' title='email address' placeholder='البريد الالكترونى' formControlName='email'/>
                    <app-field-error [displayError]="isFieldValid('email')" errorMsg='رجاء قم بإدخال بريد الكتروني صحيح'></app-field-error>
                    <textarea title='your message' placeholder='رسالتك' formControlName='notes'></textarea>
                    <app-field-error [displayError]="isFieldValid('notes')" errorMsg='الرجاء إدخال رسالتك ويجب أن تكون أكثر من 20 حرفًا'></app-field-error>
                    <button class='btn' [disabled]='submitted'>إرسال</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
      </section>
  
      <div class="row">
        <div class="row footer">
          <div class="col-4">
            <ul>
              <li style="color: white; list-style-type: none; cursor: unset;">
                زورونا  :  17 جنوب الاكاديميه الدور الارضي القاهره الجديده
              </li> 
              <li style="color: white; list-style-type: none;">
                <a class='nav-link' href='tel:+201014422400' style="text-decoration: none; font: normal normal bold 19px/24px Abhaya Libre; color: white;">
            
                  <span>01014422400</span>
                   <i class="fas fa-phone ml-1"></i>
                </a>
              </li> 
            </ul>
          </div>
          <div class="col-4">
            <img src="../../assets/Mixers -Final Version/Logo/Group 18.png" width="240px" class="img-fluid" alt="">
    
          </div>
          <div class="col-4">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3454.2698686210433!2d31.437647015114752!3d30.029114681887958!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14583dd69d65c4f7%3A0xf49b5170342148ef!2sAligore%20%26%20myth%20consultant%20company!5e0!3m2!1sen!2seg!4v1611130902106!5m2!1sen!2seg" width="400" height="400" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
    
          </div>
          <!-- <footer class="text-center">
              
              <img src="../../assets/Mixers -Final Version/Logo/Group 18.png" width="240px" class="img-fluid" alt="">
            <div class="col-4">
              <ul>
                <li style="color: white; list-style-type: none; cursor: unset;">
                  Location : 17 south academy Ground Floor New Cairo
                </li> 
                <li style="color: white; list-style-type: none;">
                  <a class='nav-link' href='tel:+201014422400' style="text-decoration: none; font: normal normal bold 19px/24px Abhaya Libre; color: white;">
              
                    <span>01014422400</span>
                     <i class="fas fa-phone ml-1"></i>
                  </a>
                </li> 
              </ul>
            </div>
            <div class="col-6">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3454.2698686210433!2d31.437647015114752!3d30.029114681887958!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14583dd69d65c4f7%3A0xf49b5170342148ef!2sAligore%20%26%20myth%20consultant%20company!5e0!3m2!1sen!2seg!4v1611130902106!5m2!1sen!2seg" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
            </div>
            
          </footer> -->
        </div>

 
      </div>
  </div>