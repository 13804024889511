<div class="container-fluid">

  <nav class="navbar navbar-expand-lg navbar-light bg-white ">
    <div class="container-fluid ">
      <button class="navbar-toggler ml-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse col-lg-7" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto mb-2 mb-lg-0">
          <li class="nav-item col-4">
            <a class="nav-link active" aria-current="page" >Home</a>
          </li>
          <li class="nav-item col-4">
            <a class="nav-link" (click)= "scrollTo(target)" >About</a>
          </li>
          <li class="nav-item col-4">
            <a class="nav-link" (click)= "scrollTo(target2)" >Interiors</a>
          </li>
          <li class="nav-item col-4">
            <a class="nav-link" (click)= "scrollTo(target3)">Contact</a>
          </li>
          <li class="nav-item col-4">
            <a class="nav-link" [routerLink]="['/ar']" >Arabic</a>
          </li>
          
        </ul>
        
      </div>
      <div class="col-auto">
        <li class='list-inline-item nav-item phone '>
          <a class='nav-link' href='tel:+201014422400' style="text-decoration: none; font: normal normal bold 19px/24px Abhaya Libre; color: black;">
          
            <span>01014422400</span>
             <i class="fas fa-phone ml-1"></i>
          </a>
        </li> 

    </div>
    </div>
  </nav>
  
    <div class="row">
      <div class="col-lg-4 col-md-8 text-center">
        <img src="../../assets/Mixers -Final Version/Logo/Group 4.png"  width="165px" alt="">
        <div class="left-nav" >

            <h3>Best Luxury Finishes Only for You</h3>
            <p class="mt-4">We are catered to build the dream homes of our unique clients through our extensive expertise and credibility in the interior design industry.</p>
            <button class="btn mt-5 pl-4 pr-4" (click)= "scrollTo(target2)"> Get Started</button>
        </div>
      </div>
      <div class="col-lg-6 col-md-7" style="margin-top: 110px;">
        <div id="carousel-fade" class="carousel carousel-fade" data-ride="carousel" data-interval="2000">
      
      
          <div class="carousel-inner embed-responsive embed-responsive-16by9" role="listbox">
            <div class="carousel-item embed-responsive-item active">
              <img src="../../assets/Mixers -Final Version/Images/1.jpeg" class="img-responsive center-block" alt="First slide image" class="img-fluid">
              <div class="carousel-caption">
    
              </div>
            </div>
      
            <div class="carousel-item embed-responsive-item">
              <img src="../../assets/Mixers -Final Version/Images/2.jpeg" class="img-responsive center-block" alt="Second slide image" style="height: inherit;" width="100%">
              <div class="carousel-caption">
             
              </div>
            </div>
      
            <div class="carousel-item embed-responsive-item">
              <img src="../../assets/Mixers -Final Version/Images/3.jpeg" class="img-responsive center-block" alt="Third slide image" style="height: inherit;" width="100%">
              <div class="carousel-caption">
          
              </div>
            </div>
          </div>

          <div class="row">

            <a class="carousel-control-prev col-1" href="#carousel-fade" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next col-1" href="#carousel-fade" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
            
          </div>
        </div>
    </div>
    <div class="row mt-5">
      <div class="col-lg-6 text-end">
        <H2> Get .. Save .. Relax </H2>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-lg-1 icon-class">
        <i class="fas fa-award"></i>

        
      </div>
      <div class="col-lg-3 text-class">
        <h5>Highest Quality</h5>
        <p>
          All of our Furniture Uses the Best Materials and Choices for our Customers.
        </p>
      </div>
      <div class="col-lg-1 icon-class">
        <i class="fas fa-truck"></i>
      </div>
      <div class="col-lg-3 text-class">
        <h5>Free Shipping</h5>
        <p>Free Shipping evertime you buy furniture from us without exception.</p>
      </div>
      <div class="col-lg-1 icon-class">
        <i class="far fa-check-circle"></i>
      </div>
      <div class="col-lg-3 text-class">
        <h5>
          Warranty
        </h5>
        <p>
          Evertime you buy our furniture products, you will get a warranty without exception.
        </p>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-lg-5 position-relative ">
        <div class="drawing">
        </div>
          <img src="../../assets/Mixers -Final Version/Images/WhatsApp Image 2020-12-20 at 11.31.30 AM.jpeg" class="img-fluid" alt="">
      </div>
      <div class="col-lg-6 " #target>
        <h4>We Provide you the Best Experience</h4>
        <p class="exp">
          Mixiers is an Interior Design Firm that magnifies the high-end life of its clients through delivering a hybrid of harmonious living spaces maintaining an ultimate balance between beauty, details, and art.        </p>
      </div>
    </div>

    <div class="container">

      <div class="row mt-5">
        <div class="col-lg-4 numbers text-center">
          <h3 class="number-section">250+</h3>
          <p>Style of furniture <br> lighting, and more</p>
          <hr>
          <h3 class="number-section">500+</h3>
          <p>Clients ranging from small <br> Homes to large one</p>
          <hr>
          <h3 class="number-section">20+</h3>
          <p>Years of experience in interior <br> design industry</p>
        </div>
        <div class="col-lg-7 col-auto">
          <img src="../../assets/Mixers -Final Version/Images/2.jpeg" class="img-fluid" alt="">
        </div>
        <!-- <div class="col-lg-3 col-4">
          <img class="small-img" src="../../assets/Mixers -Final Version/Images/2.jpeg"  width="100%" alt="">
        </div> -->
      </div>
    </div>


    <div class="row mt-5 ml-3 position-relative" #target2>
      <div class="col-12">

        <h4>Our Featured Projects</h4>
      </div>
      <div class="col-12">

        <owl-carousel-o class="first-owl" [options]="customOptions2">
          <ng-template carouselSlide >
            <img src="../../assets//Mixers -Final Version/Images/WhatsApp Image 2020-12-20 at 11.31.26 AM.jpeg" style="height: 371px;" alt="" class="img-fluid" >
          </ng-template>  
          <ng-template carouselSlide>
            <img src="../../assets/Mixers -Final Version/Images/WhatsApp Image 2020-12-20 at 11.31.27 AM.jpeg" alt="" style="height: 371px;" class="img-fluid">
          </ng-template>  
          <ng-template carouselSlide>
            <img src="../../assets/Mixers -Final Version/Images/WhatsApp Image 2020-12-20 at 11.31.28 AM.jpeg" alt="" style="height: 371px;" class="img-fluid">
          </ng-template>  
          <ng-template carouselSlide>
            <img src="../../assets/Mixers -Final Version/Images/WhatsApp Image 2020-12-20 at 11.31.29 AM.jpeg" alt="" style="height: 371px;" class="img-fluid">
          </ng-template>  
          <ng-template carouselSlide>
            <img src="../../assets/Mixers -Final Version/Images/WhatsApp Image 2020-12-20 at 11.31.30 AM.jpeg" alt="" style="height: 371px;" class="img-fluid">
          </ng-template>  
          <ng-template carouselSlide>
            <img src="../../assets/Mixers -Final Version/Images/WhatsApp Image 2020-12-20 at 11.31.31 AM.jpeg" alt="" style="height: 371px;" class="img-fluid">
          </ng-template>  
        </owl-carousel-o>
      </div>
    </div>
      <!-- <div class="drawing2"></div> -->
    <div class="row mt-5">
      <h4 class="col-5 ml-4">What our Customer Are Saying</h4>
      <div class="col-12 mt-5 hand-slider">

        <owl-carousel-o [options]="customOptions" class="second-carousel">
          <ng-template carouselSlide class="p-5">
             <!-- <h3>The service Provider is very good and friendly</h3>  -->
            <b>Before hearing of Mixiers, I struggled with speedy resolution. That's when I tried Mixiers, I got immediate responses to all my inquiries and requests. Mixiers is an amazing and trustworthy company. Highly recommend it!</b>
          <h5 class="pt-4">Mr Eslam Farouk</h5>
            <!-- <p>Product Manger</p>  -->
          </ng-template>  
          <ng-template carouselSlide class="p-5">
           <b>If you've been disappointed by other interior design companies, I can't recommend Mixiers enough. A professional company that will meet your expectations.</b>
           <h5 class="pt-5">Mr Mostafa Hamed</h5>

         </ng-template>  
         <ng-template carouselSlide>
         <b>I wanted to finish my home quickly but I know it won't be easy to find an interior design company that would satisfy all my needs. Until I got a recommendation from a friend to contact Mixiers. The team NAILED it with the materials, speed, and everything. Just a great service!</b>
         <h5 class="pt-4">Mr Basem Hussien</h5>

       </ng-template>  
       <ng-template carouselSlide>
        <b>I had a great experience designing my home with Mixiers. They made sure they understood my style and vision. They put together the perfect design for me, ensuring a high quality</b>
        <h5 class="pt-5">Mr Mohamed Moanes</h5>

      </ng-template>  
      <ng-template carouselSlide>
        <b>My husband and I didn't know exactly what we wanted. We definitely needed help to design our new house and that just happened when we found Mixiers. They provided us with the perfect design ideas within our budget. Would highly recommend them!</b>
        <h5 class="pt-4">Ms Hanaa El Hatto</h5>

      </ng-template> 
      <ng-template carouselSlide>
        <b>If you are looking for the perfect home finishing, I would recommend you Mixiers. They are professional, fast, and offer the best quality.</b>
        <h5 class="pt-5">Mr Youssef Raffat</h5>

      </ng-template> 
        </owl-carousel-o>
      </div>
    </div>

    <div class="row ">
      <h1 class="m-auto col-lg-5">
        Get in Touch
      </h1>
    
    </div>
    <!-- <div class="row mt-3">
      <p class="m-auto col-5 text-center" style="color: #808285;">
         enter your inquery below to get in touch.
      </p>
    </div> -->

    <section #contact class='contact-us' #target3>
      <div class='container'>
        <div class='row'>
          <div class='col-lg-5 col-md-6 col-sm-10 mx-auto'>
            <div class='form-con'>
              <p>let's talk! we would love to hear from you, write us if you have any inquiries or would like to know more about our projects.</p>
              <form [formGroup]='updateForm' (ngSubmit)='onSubmit()'>
                <input type='text' title='name' placeholder='name' formControlName='name'/>
                <app-field-error [displayError]="isFieldValid('name')" errorMsg='Please Enter your name'></app-field-error>
                <input type='text' title='phone number' placeholder='phone number' formControlName='phone'/>
                <app-field-error [displayError]="isFieldValid('phone')" errorMsg='Please Enter a valid phone number'></app-field-error>
                <input type='email' title='email address' placeholder='email address' formControlName='email'/>
                <app-field-error [displayError]="isFieldValid('email')" errorMsg='Please Enter a valid email address'></app-field-error>
                <textarea title='your message' placeholder='your message' formControlName='notes'></textarea>
                <app-field-error [displayError]="isFieldValid('notes')" errorMsg='Please Enter your message & must be more than 20 character'></app-field-error>
                <button class='btn' [disabled]='submitted'>submit message</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="row footer">
      <div class="col-4">
        <ul>
          <li style="color: white; list-style-type: none; cursor: unset;">
            Location : 17 south academy Ground Floor New Cairo
          </li> 
          <li style="color: white; list-style-type: none;">
            <a class='nav-link' href='tel:+201014422400' style="text-decoration: none; font: normal normal bold 19px/24px Abhaya Libre; color: white;">
        
              <span>01014422400</span>
               <i class="fas fa-phone ml-1"></i>
            </a>
          </li> 
        </ul>
      </div>
      <div class="col-4">
        <img src="../../assets/Mixers -Final Version/Logo/Group 18.png" width="240px" class="img-fluid" alt="">

      </div>
      <div class="col-4">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3454.2698686210433!2d31.437647015114752!3d30.029114681887958!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14583dd69d65c4f7%3A0xf49b5170342148ef!2sAligore%20%26%20myth%20consultant%20company!5e0!3m2!1sen!2seg!4v1611130902106!5m2!1sen!2seg" width="400" height="400" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>

      </div>
      <!-- <footer class="text-center">
          
          <img src="../../assets/Mixers -Final Version/Logo/Group 18.png" width="240px" class="img-fluid" alt="">
        <div class="col-4">
          <ul>
            <li style="color: white; list-style-type: none; cursor: unset;">
              Location : 17 south academy Ground Floor New Cairo
            </li> 
            <li style="color: white; list-style-type: none;">
              <a class='nav-link' href='tel:+201014422400' style="text-decoration: none; font: normal normal bold 19px/24px Abhaya Libre; color: white;">
          
                <span>01014422400</span>
                 <i class="fas fa-phone ml-1"></i>
              </a>
            </li> 
          </ul>
        </div>
        <div class="col-6">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3454.2698686210433!2d31.437647015114752!3d30.029114681887958!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14583dd69d65c4f7%3A0xf49b5170342148ef!2sAligore%20%26%20myth%20consultant%20company!5e0!3m2!1sen!2seg!4v1611130902106!5m2!1sen!2seg" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
        </div>
        
      </footer> -->
    </div>
</div>